
import { Component, Vue } from "vue-property-decorator"
import { ConsultantProfileQuery, ConsultantProfile } from "@/gql"
import MissionUpdates from "@/modules/Missions/components/MissionUpdates/MissionUpdates.vue"
import ConsultantHomeWallet from "@/components/consultant/ConsultantHomeWallet.vue"
import OutstandingAppraisals from "@/components/consultant/OutstandingAppraisals.vue"
import EarnParelteePoints from "@/components/consultant/EarnParelteePoints.vue"
import TalentOfTheWeek from "@/components/consultant/TalentOfTheWeek.vue"
import ConsultantsFeed from "@/components/consultant/ConsultantsFeed.vue"
import { NetworkStatus } from "@apollo/client/core"
import TasksWidget from "@/components/tasks/TasksWidget.vue"
import AppPage from "@/components/layout/AppPage.vue"
import OpenRolesCard from "@/modules/Consultant/Homepage/OpenRolesCard.vue"
import OnboardingCheckListCard from "@/modules/Consultant/Homepage/OnboardingCheckListCard.vue"
import { useConsultantOnboardingChecklist } from "@/modules/Consultant/hooks/useConsultantOnboardingChecklist"
import UnreadConversations from "@/modules/Consultant/Homepage/UnreadConversations.vue"
import PendingMissionDeliverables from "@/modules/Consultant/Homepage/PendingMissionDeliverables.vue"
import ConsultantProfileSummary from "@/modules/Consultant/Homepage/ConsultantProfileSummary.vue"

@Component({
  components: {
    TasksWidget,
    ConsultantProfileSummary,
    OutstandingAppraisals,
    MissionUpdates,
    ConsultantHomeWallet,
    EarnParelteePoints,
    TalentOfTheWeek,
    ConsultantsFeed,
    AppPage,
    OpenRolesCard,
    OnboardingCheckListCard,
    UnreadConversations,
    PendingMissionDeliverables,
  },
  apollo: {
    consultantProfile: {
      query: ConsultantProfileQuery,
      result(result) {
        if (
          result.networkStatus === NetworkStatus.ready ||
          result.networkStatus === NetworkStatus.error
        ) {
          this.loading = false
        }
      },
      error() {
        this.errorLoading = true
      },
    },
  },
})
export default class HomePage extends Vue {
  loading = true

  consultantProfile!: ConsultantProfile
  errorLoading = false

  get hasOnboardingItems() {
    if (!this.consultantProfile) return false
    const { hasOnboardingItems } = useConsultantOnboardingChecklist(this.consultantProfile)
    return hasOnboardingItems.value
  }
}
